<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <div class="text-right list-unstyled">
          <locale />
        </div>
        <b-link class="brand-logo">
          <b-img
              :src="appLogoImage"
              style="max-height: 100px"
              alt="logo"
          />
        </b-link>
        <b-card-title class="mb-1">
          {{ $t('register_page.title') }} 🚀
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('register_page.sub_title') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm" #default="{invalid}">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- full_name -->
            <b-form-group>
              <label for="full_name"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_full_name') }}</label>
              <validation-provider
                #default="{ errors }"
                name="full_name"
                rules="required"
              >
                <b-form-input
                  id="full_name"
                  v-model="full_name"
                  :state="errors.length > 0 ? false: full_name != null ? true : null"
                  name="register-full_name"
                  placeholder="Ali Kaplan"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone number -->
            <b-form-group>
              <label for="phone_number"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_phone_number') }}</label>
              <!-- TODO zorunlu alan button ile entegre çalışmıoyor. Hata var!!             -->
              <validation-provider
                  #default="{ errors }"
                  name="phone_number"
                  rules="required"
              >
                <vue-tel-input
                    v-model="phone_number"
                    mode="international"
                    id="phone_number"
                    :default-country="countryCode"
                    @validate="phone_number_check_valid"
                    :input-options="custom_inputOptions"
                >
                  <template v-slot:arrow-icon><span></span></template>
                </vue-tel-input>
                <small
                    v-if="phone_number_is_valid==false"
                    class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>

            <!-- company_name -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="company_name"
                rules="required"
              >
                <label for="company_name"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_company_name') }}</label>
                <b-form-input
                  id="company_name"
                  v-model="company_name"
                  :state="errors.length > 0 ? false: company_name != null ? true : null"
                  name="register-company_name"
                  :placeholder="$t('register_page.lbl_company_name')"

                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group>
              <label for="email"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_email') }}</label>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false: regEmail != null ? true : null"
                  name="register-email"
                  placeholder="alikaplan@geribildir.io"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <label for="password"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_password') }}</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false: password != null ? true : null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <div>
              <validation-provider
                  #default="{ errors }"
                  name="checkbox"
                  rules="required"
              >
                <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="chekcbox_status"
                    :value="true"
                    :unchecked-value="null"
                    switch
                    :state="chekcbox_status != null ? true : null"
                    name="checkbox-1"
                >
                  <b-link
                      :href="VUE_APP_USAGE_TERMS"
                      target="_blank"
                  >{{ $t('register_page.privacy_policy_and_terms') }}</b-link>
                </b-form-checkbox>
                <small
                    v-if="chekcbox_status == false"
                    class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </div>

            <!-- submit button -->
            <b-button
                :disabled="invalid"
                variant="primary"
              block
              type="submit"
            >
              {{ $t('register_page.register') }}
            </b-button>
          </b-form>
        </validation-observer>
        <!--        <div class="divider my-2">-->
        <!--          <div class="divider-text">-->
        <!--            or-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        &lt;!&ndash; social buttons &ndash;&gt;-->
        <!--        <div class="auth-footer-btn d-flex justify-content-center">-->
        <!--          <b-button-->
        <!--              variant="facebook"-->
        <!--              href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="FacebookIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--              variant="twitter"-->
        <!--              href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="TwitterIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--              variant="google"-->
        <!--              href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="MailIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--              variant="github"-->
        <!--              href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="GithubIcon" />-->
        <!--          </b-button>-->
        <!--        </div>-->
      </b-card>
      <b-card class="mt-1 text-center">
        <span>{{ $t('register_page.do_you_have_an_account') }} </span>
        <b-link :to="{name:'auth-login'}">
          <span>{{ $t('register_page.login') }}</span>
        </b-link>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import useJwt from '@/auth/jwt/useJwt'
import {$themeConfig} from "@themeConfig";
import {VueTelInput} from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import axios from "axios";

export default {
  components: {
    BImg,
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,
    VueTelInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      VUE_APP_USAGE_TERMS: process.env.VUE_APP_USAGE_TERMS,
      regEmail: null,
      full_name: null,
      company_name: null,
      password: null,
      chekcbox_status: null,
      // validation rules
      required,
      email,
      custom_inputOptions: {
        placeholder: '',
      },
      phone_number: null,
      phone_number_object: null,
      phone_number_is_valid: null,
      countryCode: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  mounted() {
    this.getCountryCode()
  },
  methods: {
    async getCountryCode() {
      try {
        const response = await axios.get('http://ip-api.com/json/')
        this.countryCode = response.data.countryCode
      } catch (error) {
        console.error('Failed to get country code:', error)
      }
    },
    phone_number_check_valid(aaa) {
      this.phone_number_is_valid = aaa.valid
      this.phone_number_object = aaa
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.createCustomer({
            name: this.full_name,
            phone_number: this.phone_number_object.number,
            email: this.regEmail,
            password: this.password,
            company_name: this.company_name,
          })
            .then(response => {
              useJwt.setToken(response.data.access)
              useJwt.setRefreshToken(response.data.refresh)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push({ name: 'auth-register-planselect' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('register_page.registration_created'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
