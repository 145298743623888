var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('div',{staticClass:"text-right list-unstyled"},[_c('locale')],1),_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('register_page.title'))+" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('register_page.sub_title'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"full_name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('register_page.lbl_full_name')))]),_c('validation-provider',{attrs:{"name":"full_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full_name","state":errors.length > 0 ? false: _vm.full_name != null ? true : null,"name":"register-full_name","placeholder":"Ali Kaplan"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"phone_number"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('register_page.lbl_phone_number')))]),_c('validation-provider',{attrs:{"name":"phone_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"mode":"international","id":"phone_number","default-country":_vm.countryCode,"input-options":_vm.custom_inputOptions},on:{"validate":_vm.phone_number_check_valid},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('span')]},proxy:true}],null,true),model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),(_vm.phone_number_is_valid==false)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"company_name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('register_page.lbl_company_name')))]),_c('b-form-input',{attrs:{"id":"company_name","state":errors.length > 0 ? false: _vm.company_name != null ? true : null,"name":"register-company_name","placeholder":_vm.$t('register_page.lbl_company_name')},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('register_page.lbl_email')))]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false: _vm.regEmail != null ? true : null,"name":"register-email","placeholder":"alikaplan@geribildir.io"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('register_page.lbl_password')))]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false: _vm.password != null ? true : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('validation-provider',{attrs:{"name":"checkbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","value":true,"unchecked-value":null,"switch":"","state":_vm.chekcbox_status != null ? true : null,"name":"checkbox-1"},model:{value:(_vm.chekcbox_status),callback:function ($$v) {_vm.chekcbox_status=$$v},expression:"chekcbox_status"}},[_c('b-link',{attrs:{"href":_vm.VUE_APP_USAGE_TERMS,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('register_page.privacy_policy_and_terms')))])],1),(_vm.chekcbox_status == false)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1),_c('b-button',{attrs:{"disabled":invalid,"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('register_page.register'))+" ")])],1)]}}])})],1),_c('b-card',{staticClass:"mt-1 text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('register_page.do_you_have_an_account'))+" ")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(_vm._s(_vm.$t('register_page.login')))])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }